<template>
    <v-dialog
        v-model="visible"
        :persistent="persistent"
        :max-width="maxWidth"
        @keydown:esc="close"
    >
        <v-card :loading="loading" :disabled="loading">
          <v-row v-if="styledHeader && title" dense no-gutters class="shrink">
            <v-theme-provider dark>
              <v-toolbar dark color="breeze_blue" flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="close"
                    :disabled="loading"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
            </v-theme-provider>
          </v-row>
            <v-card-title v-if="!styledHeader && title">{{ title }}</v-card-title>
            <v-card-text :class="styledHeader? 'pt-6' : ''">
                <slot />
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="visible=false"
                    v-html="okOnly ? okText : noText"
                    :disabled="loading"
                />
                <v-btn
                    v-if="!okOnly"
                    :color="yesColor"
                    text
                    @click="$emit('click')"
                    :loading="loading"
                    :disabled="yesDisabled"
                    ><v-icon v-if="yesIcon != null && yesIcon != ''">{{ yesIcon }}</v-icon>{{ yesText }}</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'SimpleDialog',
    mixins: [dialogMixin],
    methods: {
        close() {
            this.visible = false;
        },

    },
    props: {
        title: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: Number,
            default: 500,
        },
        yesColor: {
            type: String,
            default: 'primary',
        },
        okOnly: {
            type: Boolean,
            default: false,
        },
        okText:{
          type: String,
          default: 'OK',
        },
        noText: {
            type: String,
            default: 'No',
        },
        yesText: {
            type: String,
            default: 'Yes',
        },
        yesIcon: {
          type: String,
          default: null,
        },
        yesDisabled: {
            type: Boolean,
            default: false,
        },
        styledHeader:{
          type: Boolean,
          default: false,
        }
    },
};
</script>

<style scoped>
    .v-card__text, .v-card__title {
        word-break: normal !important; /* maybe !important  */
    }
</style>